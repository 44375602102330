import DashSideNavBar from './DashSideNavBar';
import DashNavBar from './DashNavBar';
import { useEffect, useState } from 'react';
import DashFooter from './DashFooter';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

import axios from 'axios';

import Calendar from 'react-calendar';
import LoadWaterbill from './LoadWaterbill';


function Dashboard() {
    document.title="DashboardHome";
    const [closed,setClosed]=useState(false)
    const [isOpen, setIsOpen] = useState(false)

    // Array to store month string values
  const allMonthValues = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];


  // State for date selected by user
  const [selectedDate, setSelectedDate] = useState(new Date());

  // State for text above calander
  const [calendarText, setCalendarText] = useState(selectedDate.toDateString());

  // Function to update selected date and calander text
  const handleDateChange = (value) => {
    setSelectedDate(value);
    setCalendarText(value.toDateString());
  };

  // Function to handle selected Year change
  const handleYearChange = (value) => {
    const yearValue = value.getFullYear();
    setCalendarText(`${yearValue} Year`);
  };

  // Function to handle selected Month change
  const handleMonthChange = (value) => {
    const monthValue = allMonthValues[value.getMonth()];
    const yearValue = value.getFullYear();
    setCalendarText(`${value.getMonth()} , ${yearValue} `);
  };


    const [date, setDate] = useState(new Date());

    const [show,setShow]=useState(false);
    const [showdownloadpayments,setShowDownloadPayments]=useState(false);
    const [property,setProperty]=useState('');
    const [propertyid,setPropertyId]=useState('');

    const [showmonth,setShowMonth]=useState(false);

    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(true);
    const [loadingmonths,setLoadingMonths]=useState(true);
    const [loadingwater,setLoadingWater]=useState(true);
    const [loadingstats,setLoadingStats]=useState(true);
    

    const [url,setUrl]=useState('');
    const [authname,setAuthName]=useState('');

    const [prevmonths,setPrevMonths]=useState([]);
    const [currentmonth,setCurrentMonth]=useState('');
    const [currentmonthname,setCurrentMonthName]=useState('');
    
    const [selectedmonth,setSelectedMonth]=useState('');
    const [totalmonths,setTotalMonths]=useState(0);

    const [waterbill,setWaterbill]=useState([]);
    const [stats,setStats]=useState([]);
    const [totalwaterbillproperties,setTotalWaterbillProperties]=useState(0);
    const [payments,setPayments]=useState([]);

    // /properties/dash/payments
    // /properties/dash/water
    // /properties/dash/water/prev
    // /properties/update/waterbill/1/2022 12
    // /properties/updateload/waterbill/1/2022 10

    // useEffect(()=>{
    //     axios.get('/api/isAuthencticated')
    //         .then(response=>{
    //             if(response.data.status=== 200){
    //                 setAuthName(localStorage.getItem("auth_name")); 
    //             }
    //             setLoading(false)
    //         })
    //         .catch((error)=>{
    //             setAuthName('');
    //             setRedirect(true);
    //             setUrl('/login');
    //         })

    //         return () =>{
    //             setAuthName('');
    //             setRedirect(true);
    //             setUrl('/login');
    //         };
    // },[])

    

    useEffect(()=>{
        let doneloading=true;
        if (doneloading) {
            setLoadingMonths(true)
            setPrevMonths([]);
            setCurrentMonth('');
            setCurrentMonthName('');
            setTotalMonths(0);
        }
        const getPrevMonths = async (e) => { 
            await axios.get('/api/dash/month/prev')
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        setPrevMonths(response.data.previousmonths);
                        setCurrentMonth(response.data.currentdate);
                        setCurrentMonthName(response.data.currentmonthname);
                        setSelectedMonth(0);
                        setTotalMonths(response.data.totals);
                        setLoadingMonths(false)
                    }
                    else{
                        setPrevMonths([]);
                        setCurrentMonth('');
                        setCurrentMonthName('');
                        setTotalMonths(0);
                        setLoadingMonths(false)
                    }
                    setLoadingMonths(false)
                }
            })
            .catch(error=>{
                setPrevMonths([]);
                setCurrentMonth('');
                setCurrentMonthName('');
                setTotalMonths(0);
            })
        };
        getPrevMonths();

        return ()=>{
            doneloading=false;
        }
    },[])

    // useEffect(()=>{
    //     let doneloading=true;
    //     setLoadingStats(true)
    //     const getDashStats = async (e) => { 
    //         const sno=(selectedmonth==='')?0:selectedmonth;
    //         const month=(!loadingmonths)?prevmonths[sno].month:0;
    //         await axios.get(`/api/dash/${month}`)
    //         .then(response=>{
    //             if (doneloading) {
    //                 if(response.data.status=== 200){
    //                     setStats(response.data.stats);
    //                     setLoadingStats(false)
    //                 }
    //             }
    //         })
    //         .catch(error=>{
    //             setLoadingStats(false)
    //             setStats([]);
    //         })
    //     };
    //     getDashStats();

    //     return ()=>{
    //         doneloading=false;
    //     }
    // },[selectedmonth])


    // useEffect(()=>{
    //     let doneloading=true;
    //     setLoadingWater(true)
    //     const getWaterbill = async (e) => { 
    //         const sno=(selectedmonth==='')?0:selectedmonth;
    //         const month=(!loadingmonths)?prevmonths[sno].month:0;
    //         await axios.get(`/api/dash/water/${month}`)
    //         .then(response=>{
    //             if (doneloading) {
    //                 if(response.data.status=== 200){
    //                     setWaterbill(response.data.waterbill);
    //                     setTotalWaterbillProperties(response.data.totals);
    //                     setLoadingWater(false)
    //                 }
    //             }
    //         })
    //         .catch(error=>{
    //             setWaterbill([]);
    //         })
    //     };
    //     getWaterbill();

    //     return ()=>{
    //         doneloading=false;
    //     }
    // },[selectedmonth])

    

    const handleClose = () => {
        setShow(false);
        setProperty('');
        setPropertyId('');
    };

    const handleShow = (propert) => {
        setShow(true);
        setProperty(propert.plotname);
        setPropertyId(propert.id);
    };

    const handlePaymentClose = () => {
        setShowDownloadPayments(false);
        setProperty('');
        setPropertyId('');
    };

    const handlePaymentShow = (names,id) => {
        setShowDownloadPayments(true);
        setProperty(names);
        setPropertyId(id);
    };

    const handleMonth =(months) =>{
        setLoadingMonths(true)
        setLoadingWater(true)
        setSelectedMonth(months.sno);
        setShowMonth(false)
        setLoadingMonths(false)
    }

    const handleNext =() =>{
        setLoadingMonths(true)
        setLoadingWater(true)
        if(selectedmonth > 0){
            setSelectedMonth(selectedmonth-1)
        }
        else{
            setLoadingWater(false)
        }
        setLoadingMonths(false)
    }

    const handlePrev =() =>{
        setLoadingMonths(true)
        setLoadingWater(true)
        if(selectedmonth < totalmonths){
            setSelectedMonth(selectedmonth+1)
        }
        else{
            setLoadingWater(false)
        }
        setLoadingMonths(false)
    }


    // axios.interceptors.response.use(undefined,function axiosRetryInterceptor(err){
    //     if(err.response.status===401){
    //         setAuthName('');
    //         setRedirect(true);
    //         setUrl('/login');
    //     }
    //     return Promise.reject(err);
    // });

    var prevmonthslist="";
    if(!loadingmonths){
        prevmonthslist=
        prevmonths && prevmonths.map((months,key) => ( 
            <div key={key}>
                {key <= 11 &&
                    <>
                        {months.month === currentmonth &&
                            <div key={key} className='m-1 mt-0 mb-0 p-0 pt-0 pb-0' onClick={()=>{handleMonth(months)}}>
                                <span className='btn btn-info btn-block border-info m-0 p-0'>{months.monthname}</span>
                            </div>
                        }
                        {months.month !== currentmonth &&
                            <>
                                {!loadingmonths && prevmonths && prevmonths[selectedmonth].month===months.month?
                                <div key={key} className='m-1 mt-0 mb-0 p-0 pt-0 pb-0' onClick={()=>{handleMonth(months)}}>
                                    <span className='btn btn-light btn-block border-ok text-info m-0 p-0'>{months.monthname}</span>
                                </div>
                                :
                                <div key={key} className='m-1 mt-0 mb-0 p-0 pt-0 pb-0' onClick={()=>{handleMonth(months)}}>
                                    <span className='btn btn-outline-secondary btn-block border-info m-0 p-0'>{months.monthname}</span>
                                </div>
                                }
                            </>
                            // <div key={key} className='m-1 mt-0 mb-0 p-0 pt-0 pb-0' onClick={()=>{handleMonth(months)}}>
                            //     <span className='btn btn-outline-secondary btn-block border-info m-0 p-0'>{months.monthname}</span>
                            // </div>
                        }
                    </>
                }
                    
                {/* ) */}
            </div>
        ))
        
    }

    if(loadingmonths){
        prevmonthslist= (
            <Spinner animation="grow" variant="primary" size="lg" role="status"></Spinner>
        );
    }


    
    // if(loading){
    //     return (
    //         <DashboardSpinner />
    //     )
    // }
    // else{
    //     if(redirect){
    //         if(authname===''){
    //             return <Navigate to={url} />
    //         }
    //     }
    // }
    

  return (
    <>
    <div className="wrapper">
        <DashNavBar setClosed={setClosed} closed={closed} active='home'/>
        <DashSideNavBar setClosed={setClosed} closed={closed} active='home'/>
        {/* className={`nav-link ${active==='home'?'active':''}`} */}
        
        <main className="py-3">
            <div className={`content-wrapper ${closed?'closed':''}`} style={{"paddingTop": "10px"}}>
           
                <section className="content">
                    <div className="container">
                        <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="row m-0 p-0">
                            <div className="col-md-12 m-0 p-0 mt-2 mb-4">
                                    <div className="card border-info" >
                                        <div className="card-header bg-info text-white elevation-2 m-0 p-0">
                                            <h4 style={{"textAlign": "center"}}>Welcome all </h4>
                                        </div>

                                        <div className="card-body text-center" style={{"paddingTop": "10px"}}>
                                            
                                            <p>
                                                Welcome to Wagitonga Agencies Dashboard
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-12 m-0 p-0 mb-4">
                                    <div className="card border-none" >
                                        <div className="card-header bg-white elevation-5 m-0 p-1">
                                            <div className='monthchanger'>
                                                {!showmonth &&
                                                    <div className='monthchanger-prev'>
                                                        <button className='btn btn-outline-primary btn-block border-info m-0 p-1' onClick={handlePrev}><i className="fas fa-chevron-left"></i></button>
                                                    </div>
                                                }
                                                {showmonth && <button className='btn btn-danger border-info m-0 p-2' onClick={()=>{setShowMonth(!showmonth)}} ><i className="fas fa-times"></i></button>}
                                                <div className='monthchanger-date'>
                                                    <div className='monthchanger-date-show m-0 p-1' onClick={()=>{setShowMonth(!showmonth)}}>
                                                        <span className='m-0 p-2'><i className="fas fa-calendar"></i></span> 
                                                        <span className='m-0 p-2'>
                                                            {loadingmonths &&
                                                                <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                            }
                                                            {selectedmonth !== '' &&
                                                                !loadingmonths && prevmonths && prevmonths[selectedmonth].monthname
                                                            }
                                                            
                                                        </span>
                                                    </div>
                                                    {showmonth && 
                                                        <div className='bg-light elevation-2 border-none pt-1 pb-1' style={{"maxHeight":"300px","overflowY":"auto"}}>
                                                            {prevmonthslist}
                                                        </div>
                                                    }
                                                </div>
                                                {!showmonth &&
                                                    <div className='monthchanger-next'>
                                                        <button className='btn btn-outline-primary btn-block border-info m-0 p-1' onClick={handleNext}><i className="fas fa-chevron-right"></i></button>
                                                    </div>
                                                }
                                                {showmonth && <button className='btn btn-danger border-info m-0 p-2' onClick={()=>{setShowMonth(!showmonth)}} ><i className="fas fa-times"></i></button>}
                                            </div>
                                            
                                        </div>

                                        
                                    </div>
                                </div>


                                <div className="col-6 col-sm-4 col-md-3 col-lg-3 m-0 p-1">
                                    <div className="info-box">
                                        <span className="info-box-icon bg-info elevation-1"><i className="fas fa-cog"></i></span>

                                        <div className="info-box-content">
                                            <span className="info-box-text">Properties : <span className='title-sm bold title-text-right'>
                                                {loadingstats &&
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                }
                                                {selectedmonth !== '' &&
                                                    !loadingstats && stats && (stats[0])?stats[0].properties:0
                                                }
                                            </span></span>
                                            <span className="info-box-text">Houses : <span className='title-sm bold title-text-right'>
                                                {loadingstats &&
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                }
                                                {selectedmonth !== '' &&
                                                    !loadingstats && stats && (stats[0])?stats[0].houses:0
                                                }    
                                            </span></span>
                                            <span className="info-box-text">Tenants : <span className='title-sm bold title-text-right'>
                                                {loadingstats &&
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                }
                                                {selectedmonth !== '' &&
                                                    !loadingstats && stats && (stats[0])?stats[0].tenants:0
                                                }  
                                            </span></span>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3 col-lg-3 m-0 p-1">
                                    <div className="info-box mb-3">
                                        <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-thumbs-up"></i></span>

                                        <div className="info-box-content">
                                            <span className="info-box-text">Occupied : <span className='title-sm bold title-text-right'>
                                                {loadingstats &&
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                }
                                                {selectedmonth !== '' &&
                                                    !loadingstats && stats && (stats[0])?stats[0].occupiedhouses:0
                                                }  
                                            </span></span>
                                            <span className="info-box-text">Rent : <span className='title-sm bold title-text-right'>0 </span></span>
                                            <span className="info-box-text">Waterbill : <span className='title-sm bold title-text-right'>0 </span></span>
                                            <span className="info-box-text">Garbage : <span className='title-sm bold title-text-right'>0 </span></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3 col-lg-3 m-0 p-1">
                                    <div className="info-box mb-3">
                                        <span className="info-box-icon bg-success elevation-1"><i className="fas fa-shopping-cart"></i></span>

                                        <div className="info-box-content">
                                            <span className="info-box-text">Paid : <span className='title-sm bold title-text-right'>0 </span></span>
                                            <span className="info-box-text">Billed : <span className='title-sm bold title-text-right'>0 </span></span>
                                            {/* <span className="info-box-text">Balance : <span className='title-sm bold title-text-right'>0 </span></span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-4 col-md-3 col-lg-3 m-0 p-1">
                                    <div className="info-box mb-3">
                                        <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-users"></i></span>

                                        <div className="info-box-content">
                                            <span className="info-box-text">Occupied : <span className='title-sm bold title-text-right'>
                                                {loadingstats &&
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                }
                                                {selectedmonth !== '' &&
                                                    !loadingstats && stats && (stats[0])?stats[0].occupiedhouses:0
                                                }  
                                            </span></span>
                                            <span className="info-box-text">Vacant : <span className='title-sm bold title-text-right'>
                                                {loadingstats &&
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                }
                                                {selectedmonth !== '' &&
                                                !loadingstats && stats && (stats[0])?stats[0].vacanthouses:0
                                                }  
                                            </span></span>
                                            
                                            <span className="info-box-text">Doubles : <span className='title-sm bold title-text-right'>
                                                {loadingstats &&
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                }
                                                {selectedmonth !== '' &&
                                                    !loadingstats && stats && (stats[0])?stats[0].doublehouses:0
                                                }  
                                            </span></span>
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="col-md-12 m-0 p-0 mt-2 mb-4">
                                    <div className="card border-info" >
                                        <div className="card-header bg-white text-dark elevation-2 m-0 p-0">
                                            <h4 style={{"textAlign": "center"}}>
                                                Water Bill 
                                                {loadingmonths &&
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                }
                                                
                                                {selectedmonth !== '' &&
                                                    !loadingmonths && prevmonths && " "+prevmonths[selectedmonth].monthname
                                                }
                                                
                                             </h4>
                                            
                                        </div>

                                        <div className="card-body text-center m-1 mb-3 p-2">
                                            
                                            
                                            {loadingwater &&
                                                <Carousel className='' >
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                </Carousel>
                                            }

                                            {!loadingwater &&
                                                <LoadWaterbill waterbill={waterbill} handleShow={handleShow} />
                                            }
                                                
                                        </div>
                                    </div>
                                </div>
                                


                                <div className="col-md-12 m-0 p-0 mt-2 mb-4">
                                    <div className="card border-ok" >
                                        <div className="card-header bg-white text-dark elevation-2 m-0 p-0">
                                            <h4 style={{"textAlign": "center"}}>
                                                Payments 
                                                {loadingmonths &&
                                                    <Spinner  variant="info" size="sm" role="status"></Spinner>
                                                }
                                                
                                                {selectedmonth !== '' &&
                                                    !loadingmonths && prevmonths && " "+prevmonths[selectedmonth].monthname
                                                }
                                                
                                            </h4>
                                        </div>

                                        <div className="card-body text-center m-1 mt-3 mb-3 p-2">
                                            
                                            <Carousel className='' >
                                                <Carousel.Item >
                                                    <div className="row m-0 mb-0 ml-4 mr-4 pl-2 pr-2">
                                                        <div className="col-12 col-md-6 col-lg-4 m-0 p-1">
                                                            <div className="card-box mb-1">
                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon bg-success elevation-1">E1</span>
                                                                    <span className="card-box-icon bg-light elevation-1">0</span>
                                                                </div>
                                                                

                                                                <div className="card-box-content">
                                                                    <span className="card-box-text">41/45 </span>
                                                                    <span className="card-box-number">Kshs. 105550.00</span>
                                                                    <span className="card-box-number">Kshs. 19650.00</span>
                                                                </div>

                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon ">
                                                                        <div className='p-0 m-0'>
                                                                            <a href="#" className="btn btn-outline-primary p-0 pl-2 pr-2 m-0"> <i className='fa fa-briefcase text-sm'></i></a>
                                                                        </div>
                                                                    </span>
                                                                    <span className="card-box-icon ">
                                                                        <div className='p-0 m-0'>
                                                                            <a href="#" className="btn btn-outline-success p-0 pl-2 pr-2 m-0"> <i className='fa fa-envelope text-sm'></i></a>
                                                                        </div>
                                                                    </span>
                                                                    <span className="card-box-icon ">
                                                                        <div className='p-0 m-0'>
                                                                            <a href="#" className="btn btn-outline-secondary p-0 pl-2 pr-2 m-0" onClick={()=>{handlePaymentShow("E1","1")}}> <i className='fa fa-download text-sm'></i></a>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-lg-4 m-0 p-1">
                                                            <div className="card-box mb-1">
                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon bg-success elevation-1">E1</span>
                                                                    <span className="card-box-icon bg-light elevation-1">0</span>
                                                                </div>
                                                                

                                                                <div className="card-box-content">
                                                                    <span className="card-box-text">41/45 </span>
                                                                    <span className="card-box-number">105550.00</span>
                                                                    <span className="card-box-number">Kshs. 19650.00</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-lg-4 m-0 p-1">
                                                            <div className="card-box mb-1">
                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon bg-success elevation-1">TIM 1</span>
                                                                    <span className="card-box-icon bg-light elevation-1">0</span>
                                                                </div>
                                                                

                                                                <div className="card-box-content">
                                                                    <span className="card-box-text">41/45 </span>
                                                                    <span className="card-box-number">105550.00</span>
                                                                    <span className="card-box-number">Kshs. 19650.00</span>
                                                                </div>

                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon ">
                                                                        <div className='p-0 m-0'>
                                                                            <a href="#" className="btn btn-outline-primary p-0 pl-2 pr-2 m-0"> <i className='fa fa-briefcase text-sm'></i></a>
                                                                        </div>
                                                                    </span>
                                                                    <span className="card-box-icon ">
                                                                        <div className='p-0 m-0'>
                                                                            <a href="#" className="btn btn-outline-success p-0 pl-2 pr-2 m-0"> <i className='fa fa-envelope text-sm'></i></a>
                                                                        </div>
                                                                    </span>
                                                                    <span className="card-box-icon ">
                                                                        <div className='p-0 m-0'>
                                                                            <a href="#" className="btn btn-outline-secondary p-0 pl-2 pr-2 m-0" onClick={()=>{handlePaymentShow("ED1","1")}}> <i className='fa fa-download text-sm'></i></a>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Carousel.Item>
                                                <Carousel.Item >
                                                    <div className="row m-0 mb-0 ml-4 mr-4 pl-2 pr-2">
                                                        <div className="col-12 col-md-6 col-lg-4 m-0 p-1">
                                                            <div className="card-box mb-1">
                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon bg-success elevation-1">NG</span>
                                                                    <span className="card-box-icon bg-light elevation-1">031</span>
                                                                </div>
                                                                

                                                                <div className="card-box-content">
                                                                    <span className="card-box-text">41/45 </span>
                                                                    <span className="card-box-number">105550.00</span>
                                                                    <span className="card-box-number">Kshs. 19650.00</span>
                                                                </div>

                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon ">
                                                                        <div className='p-0 m-0'>
                                                                            <a href="#" className="btn btn-outline-primary p-0 pl-2 pr-2 m-0"> <i className='fa fa-briefcase text-sm'></i></a>
                                                                        </div>
                                                                    </span>
                                                                    <span className="card-box-icon ">
                                                                        <div className='p-0 m-0'>
                                                                            <a href="#" className="btn btn-outline-success p-0 pl-2 pr-2 m-0"> <i className='fa fa-envelope text-sm'></i></a>
                                                                        </div>
                                                                    </span>
                                                                    <span className="card-box-icon ">
                                                                        <div className='p-0 m-0'>
                                                                            <a href="#" className="btn btn-outline-secondary p-0 pl-2 pr-2 m-0" onClick={()=>{handlePaymentShow("ED1","1")}}> <i className='fa fa-download text-sm'></i></a>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-lg-4 m-0 p-1">
                                                            <div className="card-box mb-1">
                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon bg-success elevation-1">E1</span>
                                                                    <span className="card-box-icon bg-light elevation-1">0</span>
                                                                </div>
                                                                

                                                                <div className="card-box-content">
                                                                    <span className="card-box-text">41/45 </span>
                                                                    <span className="card-box-number">105550.00</span>
                                                                    <span className="card-box-number">Kshs. 19650.00</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-lg-4 m-0 p-1">
                                                            <div className="card-box mb-1">
                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon bg-success elevation-1">ESTL</span>
                                                                    <span className="card-box-icon bg-light elevation-1">0</span>
                                                                </div>
                                                                

                                                                <div className="card-box-content">
                                                                    <span className="card-box-text">41/45 </span>
                                                                    <span className="card-box-number">105550.00</span>
                                                                    <span className="card-box-number">Kshs. 19650.00</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Carousel.Item>
                                                <Carousel.Item >
                                                    <div className="row m-0 mb-0 ml-4 mr-4 pl-2 pr-2">
                                                        <div className="col-12 col-md-6 col-lg-4 m-0 p-1">
                                                            <div className="card-box mb-1">
                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon bg-success elevation-1">E1</span>
                                                                    <span className="card-box-icon bg-light elevation-1">0</span>
                                                                </div>
                                                                

                                                                <div className="card-box-content">
                                                                    <span className="card-box-text">41/45 </span>
                                                                    <span className="card-box-number">105550.00</span>
                                                                    <span className="card-box-number">Kshs. 19650.00</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-lg-4 m-0 p-1">
                                                            <div className="card-box mb-1">
                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon bg-success elevation-1">E1</span>
                                                                    <span className="card-box-icon bg-light elevation-1">0</span>
                                                                </div>
                                                                

                                                                <div className="card-box-content">
                                                                    <span className="card-box-text">41/45 </span>
                                                                    <span className="card-box-number">105550.00</span>
                                                                    <span className="card-box-number">Kshs. 19650.00</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-lg-4 m-0 p-1">
                                                            <div className="card-box mb-1">
                                                                <div className='card-icon'>
                                                                    <span className="card-box-icon bg-success elevation-1">TIM 1</span>
                                                                    <span className="card-box-icon bg-light elevation-1">0</span>
                                                                </div>
                                                                

                                                                <div className="card-box-content">
                                                                    <span className="card-box-text">41/45 </span>
                                                                    <span className="card-box-number">105550.00</span>
                                                                    <span className="card-box-number">Kshs. 19650.00</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Carousel.Item>
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                                {/* <Button variant='primary' onClick={()=>{handleShow("D1","2")}}>
                                    Show Modal
                                </Button> */}
                                <Modal show={show} onHide={handleClose} className="mt-4">
                                    <Modal.Header className='justify-content-center bg-info m-0 p-2' closeButton>
                                        <Modal.Title className='mx-auto text-white'> 
                                            <h5>Download {property} Waterbill </h5>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="card-box-links m-0 p-1 justify-content-center text-center">
                                            <div className="row m-0 p-0 justify-content-center text-center">
                                                <div className='col-12 p-0 m-1'>
                                                    <a href={process.env.REACT_APP_BACKEND_API_URL+'/properties/download/Reports/Waterbill/'+propertyid+'/'+ (!loadingmonths && prevmonths && prevmonths[selectedmonth].month)} className="p-2 m-0 pl-1 pr-1 btn btn-block btn-outline-primary"> <i className='fa fa-download text-md'> <small> {property} Waterbill for {!loadingmonths && prevmonths && prevmonths[selectedmonth].monthname}</small> </i>
                                                    </a>
                                                </div>
                                                {/* properties/download/Reports/Waterbill/1/Now */}
                                                <div className='col-12 p-0 m-1'>
                                                    <a href={process.env.REACT_APP_BACKEND_API_URL+'/properties/download/Reports/Waterbill/'+propertyid+'/Now'} className="p-2 m-0 pl-1 pr-1 btn btn-block btn-outline-primary"> <i className='fa fa-download text-md'> <small> {property} Waterbill for {selectedDate.getFullYear()}</small> </i>
                                                    </a>
                                                </div>

                                                <div className='col-12 p-0 m-1'>
                                                    <a href={process.env.REACT_APP_BACKEND_API_URL+'/properties/download/Reports/Waterbill/'+propertyid+'/Previous'} className="p-2 m-0 pl-1 pr-1 btn btn-block btn-outline-primary"> <i className='fa fa-download text-md'> <small> {property} Waterbill for {(selectedDate.getFullYear())-1}</small> </i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className='justify-content-center bg-light'>
                                        <Button variant='secondary' onClick={handleClose}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showdownloadpayments} onHide={handlePaymentClose} className="mt-4">
                                    <Modal.Header className='justify-content-center bg-success m-0 p-2' closeButton>
                                        <Modal.Title className='mx-auto text-white'> 
                                            <h5>Download {property} Payments Reports </h5>
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="card-box-links m-0 p-1 justify-content-center text-center">
                                            <div className="row m-0 p-0 justify-content-center text-center">
                                                <div className='col-12 p-0 m-1'>
                                                    <a href="#" className="p-2 m-0 pl-1 pr-1 btn btn-block btn-outline-success"> <i className='fa fa-download text-md'> <small> {property} Payments for Dec 2022({propertyid})</small> </i>
                                                    </a>
                                                </div>
                                                
                                                <div className='col-12 p-0 m-1'>
                                                    <a href="#" className="p-2 m-0 pl-1 pr-1 btn btn-block btn-outline-success"> <i className='fa fa-download text-md'> <small> {property} Payments for 2022({propertyid})</small> </i>
                                                    </a>
                                                </div>

                                                <div className='col-12 p-0 m-1'>
                                                    <a href="#" className="p-2 m-0 pl-1 pr-1 btn btn-block btn-outline-success"> <i className='fa fa-download text-md'> <small> {property} Payments for 2021({propertyid})</small> </i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer className='justify-content-center bg-light'>
                                        <Button variant='secondary' onClick={handlePaymentClose}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            
                                

                                <div className="col-md-12 m-0 p-0 mt-2 mb-4">
                                    <div className="card border-info" >
                                        <div className="card-header bg-info text-white elevation-2 m-0 p-0">
                                            <h4 style={{"textAlign": "center"}}>Welcome all </h4>
                                        </div>

                                        <div className="card-body text-center" style={{"paddingTop": "10px"}}>
                                        
                                        
                                                
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 m-0 p-0 mt-2 mb-4">
                                    <div className="card border-info" >
                                        <div className="card-header bg-info text-white elevation-2 m-0 p-0">
                                            <h4 style={{"textAlign": "center"}}>Welcome all </h4>
                                        </div>

                                        <div className="card-body text-center" style={{"paddingTop": "10px"}}>
                                            
                                            <p>
                                                Welcome to Wagitonga Agencies Dashboard
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                

                                
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="col-md-12 m-0 p-0 ">
                                <div className="card border-none" >
                                    <div className="card-header  bg-info text-white elevation-2  m-0 p-0">
                                        <h5 style={{"textAlign": "center"}}>
                                            <i className="fa fa-bell fa-fw"></i> 
                                            Notifications Panel 
                                        </h5>
                                    </div>

                                    <div className="card-body m-0 p-0 pt-2" >
                                        <div>
                                            <h2 className="calender-details">{calendarText}</h2>
                                            <div>
                                            {/* action, activeStartDate, value, view */}
                                                <Calendar 
                                                // selectRange={true}
                                                views={['month']}
                                                onClickMonth={handleMonthChange}
                                                onClickYear={handleYearChange}
                                                onChange={handleDateChange}
                                                value={selectedDate}/>
                                            </div>
                                            {/* {date.length > 0 ? (
                                            <p>
                                                <span>Start:</span>{' '} {date[0].toDateString()}
                                                &nbsp; to &nbsp;
                                                <span>End:</span> {date[1].toDateString()}
                                            </p>
                                                    ) : (
                                            <p>
                                                <span>Default selected date:</span>{' '} {date.toDateString()}
                                            </p>
                                                    )} */}
                                        </div>

                                        <div className="list-group">
                                            <a href="#" className="list-group-item">
                                                <i className="fa fa-comment fa-fw"></i> New Comment
                                                <span className="pull-right text-muted small"><em>4 minutes ago</em>
                                                </span>
                                            </a>
                                            <a href="#" className="list-group-item">
                                                <i className="fa fa-twitter fa-fw"></i> 3 New Followers
                                                <span className="pull-right text-muted small"><em>12 minutes ago</em>
                                                </span>
                                            </a>
                                            <a href="#" className="list-group-item">
                                                <i className="fa fa-envelope fa-fw"></i> Message Sent
                                                <span className="pull-right text-muted small"><em>27 minutes ago</em>
                                                </span>
                                            </a>
                                            <a href="#" className="list-group-item">
                                                <i className="fa fa-tasks fa-fw"></i> New Task
                                                <span className="pull-right text-muted small"><em>43 minutes ago</em>
                                                </span>
                                            </a>
                                            <a href="#" className="list-group-item">
                                                <i className="fa fa-upload fa-fw"></i> Server Rebooted
                                                <span className="pull-right text-muted small"><em>11:32 AM</em>
                                                </span>
                                            </a>
                                            <a href="#" className="list-group-item">
                                                <i className="fa fa-bolt fa-fw"></i> Server Crashed!
                                                <span className="pull-right text-muted small"><em>11:13 AM</em>
                                                </span>
                                            </a>
                                            <a href="#" className="list-group-item">
                                                <i className="fa fa-warning fa-fw"></i> Server Not Responding
                                                <span className="pull-right text-muted small"><em>10:57 AM</em>
                                                </span>
                                            </a>
                                            <a href="#" className="list-group-item">
                                                <i className="fa fa-shopping-cart fa-fw"></i> New Order Placed
                                                <span className="pull-right text-muted small"><em>9:49 AM</em>
                                                </span>
                                            </a>
                                            <a href="#" className="list-group-item">
                                                <i className="fa fa-money fa-fw"></i> Payment Received
                                                <span className="pull-right text-muted small"><em>Yesterday</em>
                                                </span>
                                            </a>
                                        </div>
                                        <a href="#" className="btn btn-default btn-block">View All Alerts</a>
                                    </div>
                                </div>

                                
                                
                            </div>
                        </div>
                            
                        </div>

                    </div>

                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                
                            </div>
                            <div className="col-lg-4">

                            </div>
                        </div>
                    </div>
                    {/* <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="card" >
                                    <div className="card-header text-info" style={{"backgroundColor": "transparent"}}>
                                        <h1 style={{"textAlign": "center"}}>Welcome </h1>
                                    </div>

                                    <div className="card-body" style={{"paddingTop": "10px"}}>
                                        <div className="row">

                                        <div className="p-1 m-0" >
                                            <div className="elevation-4 bg-white" >
                                            <div className="card-header bg-info" >  
                                                <span className="mx-auto text-center text-sm">Waterbill 
                                                    <span className="text-sm text-danger monthy-title"></span>
                                                </span>
                                                
                                                <span className="text-sm float-right text-white" id="monthy-title"></span>
                                            </div>
                                            <div className="card-body m-1" >
                                                <div className="">
                                                <div className=" " id="monthlywaterbills">
                                                    <p className="text-danger text-center">Please Wait ... </p>
                                                </div>
                                                </div>
                                            </div>
                                            </div> 
                                        </div>


                                        </div>


                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div> */}

                </section>
            </div>
        </main>


        <DashFooter />
      </div>
    </>
  );
}

export default Dashboard;