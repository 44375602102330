import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';


import { Link, Navigate } from 'react-router-dom';
import userlogo from '../../assets/img/avatar.png';
function DashNavBar({setClosed,closed,active}) {
    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(true);
    const [url,setUrl]=useState('');
    const [authname,setAuthName]=useState('');
    
    useEffect(()=>{
        if(!localStorage.getItem("auth_token")){
            setAuthName('');
            setRedirect(true);
            setUrl('/login');
            
        }
        else{
            setAuthName(localStorage.getItem("auth_name"));
        }
        setLoading(false)
      },[authname])

    
    useEffect(()=>{
        axios.get('/api/isAuthencticated')
            .then(response=>{
                if(response.data.status=== 200){
                    setAuthName(localStorage.getItem("auth_name")); 
                }
                setLoading(false)
            })
            .catch((error)=>{
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_name');
                setAuthName('');
                setRedirect(true);
                setUrl('/login');
            })

            return () =>{
                // setAuthName('');
                // setRedirect(true);
                // setUrl('/login');
            };
    },[authname])

    const handleLogout=(e) =>{
        e.preventDefault();
            axios.post('/api/logout')
            .then(response=>{
                if(response.data.status=== 200){
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('auth_name');
                    // Swal("Success",response.data.message,"success");
                    setRedirect(true);
                    setUrl('/login');
                }
                setLoading(false)

            })
            .catch((error)=>{
                // Swal("Not Logged Out",""+error.message,"error");
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_name');
                setRedirect(true);
                setUrl('/login');
            })
    }
    
    // axios.interceptors.response.use(undefined,function axiosRetryInterceptor(err){
    //     if(err.response.status===401){
    //         setRedirect(true);
    //         setUrl('/login');
    //     }
    //     return Promise.reject(err);
    // });

    if(redirect){
        return <Navigate to={url} />
    }

  return (
    <>
        <nav className={`main-header navbar navbar-expand fixed-top ${closed?'closed':''} border-brand p-0`}>
            <ul className="navbar-nav">
                <li className="nav-item ml-4 mr-2" onClick={()=>{setClosed(!closed)}}>
                    <Link className="nav-link" data-widget="pushmenu" to="#" role="button">
                    <i className="fas fa-bars fa-2x"></i>
                    </Link>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <Link to="/profile" className="nav-link">{authname} (Admin)</Link>
                </li>

            </ul>


            <Form className="d-flex mx-auto"> 
            {/* <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                /> */}
                <Button bg="info" variant="primary"><i className="fas fa-search"></i></Button>
            </Form> 

            <ul className="navbar-nav ml-auto">

                <li className="nav-item dropdown">
                    <NavDropdown title="Kshs 0.00" id="navbarScrollingDropdown">
                        <span className="dropdown-item dropdown-header">Africas Talking Topups</span>
                        <div className="dropdown-divider"></div>
                        <Link  className="dropdown-item">
                            <b>Paybill</b>: 525900<br/>
                            <b>Acct No</b>: WAGITONGA.api
                        </Link>
                    </NavDropdown>
                </li>


                <li className="nav-item dropdown">
                    <NavDropdown title={authname} id="navbarScrollingDropdown" bg='light'>
                            <span className="dropdown-item dropdown-header">
                                <img src={userlogo} width="30px" className="brand-image img-circle m-0 p-0 mx-auto" alt="User Image"/> {authname}
                            </span>
                        
                            {/* <Link className="dropdown-item " to="#"><img src={userlogo} width="30px" className="brand-image img-circle m-0 p-0 mx-auto" alt="User Image"/> Username</Link> */}
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item " to="/profile"><i className="fa fa-user text-lime"></i> Profile ({authname})</Link>
                            <Link className="dropdown-item " to="/profile/change-password"><i className="fa fa-lock text-warning"></i> Change Password</Link>
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item " to="#" onClick={handleLogout}><i className="fa fa-power-off text-danger"></i> Logout
                            </Link>
                    </NavDropdown>
                    

                </li>

            
            </ul>
        </nav>
        
        {/* <nav className="navbar navbar-expand-md bg-primary fixed-top shadow-sm">
          <div className="container text-center">
                  <Link className="navbar-brand" to="/">
                    <img src={logo} alt="Wagitonga Logo" className="brand-image elevation-1 m-0 " style={{"opacity": "1","width":"150px","borderRadius": "15px 15px 1px 1px","marginRight": "2%"}}/>
                  </Link>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="{{ __('Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                  </button>

                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav mx-auto" >
                          <li className="nav-item">
                              <Link className={`nav-link ${active==='home'?'active':''}`} to="/">Home</Link>
                          </li>
                          <li className="nav-item">
                              <Link className={`nav-link ${active==='properties'?'active':''}`} to="/allproperties">Properties</Link>
                          </li>

                          <li className="nav-item">
                              <Link className={`nav-link ${active==='aboutus'?'active':''}`} to="/aboutus">About Us</Link>
                          </li>

                          <li className="nav-item">
                              <Link className={`nav-link ${active==='contactus'?'active':''}`} to="/contactus">Contact Us</Link>
                          </li>

                          <li className="nav-item">
                              <Link className={`nav-link ${active==='gallery'?'active':''}`} to="/gallery">Gallery</Link>
                          </li>
                      </ul>

                     <Form className="d-flex"> 
                        <Form.Control
                          type="search"
                            placeholder="Search"
                            className="me-2"
                            aria-label="Search"
                          />
                          <Button bg="info" variant="info">Search</Button>
                      </Form> 

                      <ul className="navbar-nav ml-auto">
                          
                                <li className="nav-item">
                                    <Link className={`nav-link ${active==='login'?'active':''}`} to="/login">Login</Link>
                                </li>
                            
                             
                      </ul>
                  </div>
              </div>
          </nav> */}

    </>
    
  );
}

export default DashNavBar;