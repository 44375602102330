

function DashFooter() {
  return (
    <>
        <footer className="main-footer text-center bg-info text-white" style={{"bottom":"1%","width":"100%"}}>
            <strong> &copy;  Wagitonga Agency Limited.</strong> 
          </footer>  
    </>
  );
}

export default DashFooter;