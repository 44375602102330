import {useState } from 'react';

import Spinner from 'react-bootstrap/Spinner';

import Modal from 'react-bootstrap/Modal';

import axios from 'axios';

import Swal from 'sweetalert';



function AddHouse({showaddhouse,handleCloseAddHouse,currentproperty,currenthouse}) {
    document.title=(currenthouse==='')?'Add New House for : '+currentproperty.Plotname:'Update House : '+currenthouse.Housename;
    
    const [loading,setLoading]=useState(false);

    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');

    const [formdata,setFormData]=useState({
        Plotcode:currentproperty.Plotcode,
        Housename:currenthouse===''?currentproperty.Plotcode+"-":currenthouse.Housename,
        Rent:currenthouse.Rent,
        Deposit:currenthouse.Deposit,
        hid:currenthouse.id,
        id:currenthouse.Plot,
        Kplc:currenthouse.Kplc,
        Water:currenthouse.Water,
        Lease:currenthouse.Lease,
        Garbage:currenthouse.Garbage,
        DueDay:currenthouse.DueDay,
        error_list:[],
    });
    
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
    }

    const submitProperty= (e)=>{
        e.preventDefault();
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        
        const form={
            Plotcode:currentproperty.Plotcode,
            Housename:formdata.Housename,
            Rent:formdata.Rent,
            Deposit:formdata.Deposit,
            Kplc:formdata.Kplc,
            Water:formdata.Water,
            Lease:formdata.Lease,
            Garbage:formdata.Garbage,
            DueDay:formdata.DueDay,
            id:currentproperty.id,
            hid:currenthouse.id,
        }

        let title='Sure to '+(currenthouse!==''?'Update':'Add New')+ ' '+(currenthouse!==''?currenthouse.Housename:formdata.Housename) +' ?';
        let text='';
        if(currenthouse!==''){
            text="New Changes for "+currenthouse.Housename+" will be :"+
                (currenthouse.Housename.trim().toLowerCase()!==formdata.Housename.trim().toLowerCase()?"\n Name :: "+currenthouse.Housename+" > "+formdata.Housename:'')+
                (currenthouse.Rent!==formdata.Rent?"\n Rent :: "+currenthouse.Rent+" > "+formdata.Rent:'')+
                (currenthouse.Deposit!==formdata.Deposit?"\n Deposit :: "+currenthouse.Deposit+" > "+formdata.Deposit:'')+
                (currenthouse.Kplc!==formdata.Kplc?"\n Kplc D :: "+currenthouse.Kplc+" > "+formdata.Kplc:'')+
                (currenthouse.Water!==formdata.Water?"\n Water D :: "+currenthouse.Water+" > "+formdata.Water:'')+
                (currenthouse.Garbage!==formdata.Garbage?"\n Garbage D :: "+currenthouse.Garbage+" > "+formdata.Garbage:'')+
                (currenthouse.DueDay!==formdata.DueDay?"\n Due Date :: "+currenthouse.DueDay+" > "+formdata.DueDay:'');
        }
        else{
            text="New Information for "+formdata.Housename+" will be :"+
                "\n Name :: "+formdata.Housename+
                "\n Rent :: "+formdata.Rent+
                "\n Deposit :: "+formdata.Deposit+
                "\n Kplc D :: "+formdata.Kplc+
                "\n Water D :: "+formdata.Water+
                "\n Garbage D :: "+formdata.Garbage+
                "\n Due Date :: "+formdata.DueDay;
        }
        let url='';
        if(currenthouse!==''){
            url='/api/update/house/save';
        }
        else{
            url='/api/save/house/save';
        }

        if(text.trim()==="New Changes for "+currenthouse.Housename+" will be :"){
            Swal("Updating "+currenthouse.Housename,"You have not made any changes.");
            setLoadingRes("")
            setLoadingResOk("")
            setLoading(false);
        }
        else if(text.trim()==="New Information for "+formdata.Housename+" will be :"){
            Swal("New House","No House Infomation Specified.");
            setLoadingRes("")
            setLoadingResOk("")
            setLoading(false);
        }
        else{

            Swal({
                title:title,
                text:text,
                buttons:true,
                infoMode:true,
            })
            .then((willcontinue) =>{
                if(willcontinue){
                    axios.post(url,form)
                    .then(response=>{
                        if(response.data.status=== 200){
                            setLoadingResOk(response.data.message)
                            setFormData({...formdata,error_list:[]});
                            setLoadingRes("")
                        }
                        else if(response.data.status=== 401){
                            setLoadingRes(response.data.message)
                            setFormData({...formdata,error_list:[]});
                            setLoadingResOk("")
                        }
                        else if(response.data.status=== 500){
                            setLoadingRes(response.data.message)
                            setFormData({...formdata,error_list:[]});
                            setLoadingResOk("")
                        }
                        else{
                            setFormData({...formdata,error_list:response.data.errors});
                        }
                        setLoading(false);
                        console.log(formdata.error_list)
    
                    })
                    .catch((error)=>{
                        setLoadingRes(""+error)
                        setLoadingResOk("")
                        setLoading(false);
                    })
                }
                else{
                    setLoadingRes("")
                    setLoadingResOk("")
                    setLoading(false);
                }
            })

        }
        

    }

    

  return (
    <>
        <Modal size='lg' show={showaddhouse} onHide={handleCloseAddHouse} className='text-sm'>
        {(currenthouse ==='')?
            <>
                <Modal.Header className='justify-content-center bg-info m-0 p-2' closeButton>
                    <Modal.Title className='mx-auto text-white text-sm'> 
                        <h5>Add New House for : {currentproperty.Plotname} ({currentproperty.Plotcode})</h5>
                    </Modal.Title>
                </Modal.Header>
            </>
            :
            <>
                <Modal.Header className='justify-content-center bg-warning m-0 p-2' closeButton>
                    <Modal.Title className='mx-auto text-dark text-sm'> 
                        <h5>Update House : {currenthouse.Housename}</h5>
                    </Modal.Title>
                </Modal.Header>
            </>
            }
            
            
            <Modal.Body className='m-0 p-0'>
                <div className="row m-0 p-0 justify-content-center text-center border-none">
                    <form onSubmit={submitProperty}>
                        <div className="row m-0 p-0 mt-3 mb-3">
                            <div className="col-6 m-0 p-1">
                                <div className="border-info card p-1 elevation-2">
                                    <div className="form-group row m-0 p-1 pb-2">
                                        <label htmlFor="Housename" className="col-sm-5 col-12 col-form-label text-md-right">House Name</label>

                                        <div className="col-sm-7 col-12 m-0 p-0">
                                            <input id="Housename" type="text" className="form-control" name="Housename" value={formdata.Housename} onChange={handleInputChange} placeholder="House Name" required autoComplete="Housename" autoFocus/>
                                            {formdata.error_list && formdata.error_list.Housename && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.Housename}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row m-0 p-1 pb-2">
                                        <label htmlFor="Rent" className="col-sm-5 col-12 col-form-label text-md-right">House Rent</label>

                                        <div className="col-sm-7 col-12 m-0 p-0">
                                            <input id="Rent" type="text" className="form-control" name="Rent" value={formdata.Rent} onChange={handleInputChange} placeholder="0.00" required autoComplete="Rent" autoFocus/>
                                            {formdata.error_list && formdata.error_list.Rent && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.Rent}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row m-0 p-1 pb-2">
                                        <label htmlFor="Deposit" className="col-sm-5 col-12 col-form-label text-md-right">House Deposit</label>

                                        <div className="col-sm-7 col-12 m-0 p-0">
                                            <input id="Deposit" type="text" className="form-control" name="Deposit" value={formdata.Deposit} onChange={handleInputChange} placeholder="0.00" required autoComplete="Deposit" autoFocus/>
                                            {formdata.error_list && formdata.error_list.Deposit && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.Deposit}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="form-group row m-0 p-1 pb-2">
                                        <label htmlFor="Kplc" className="col-sm-5 col-12 col-form-label text-md-right">KPLC Deposit</label>

                                        <div className="col-sm-7 col-12 m-0 p-0">
                                            <input id="Kplc" type="text" className="form-control" name="Kplc" value={formdata.Kplc} onChange={handleInputChange} placeholder="0.00" required autoComplete="Kplc" autoFocus/>
                                            {formdata.error_list && formdata.error_list.Kplc && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.Kplc}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-6 m-0 p-1">
                                <div className="border-info card p-1 elevation-2">
                                    <div className="form-group row m-0 p-1 pb-2">
                                        <label htmlFor="Water" className="col-sm-5 col-12 col-form-label text-md-right">Water Deposit</label>

                                        <div className="col-sm-7 col-12 m-0 p-0">
                                            <input id="Water" type="text" className="form-control" name="Water" value={formdata.Water} onChange={handleInputChange} placeholder="0.00" required autoComplete="Water" autoFocus/>
                                            {formdata.error_list && formdata.error_list.Water && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.Water}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>


                                    <div className="form-group row m-0 p-1 pb-2">
                                        <label htmlFor="Lease" className="col-sm-5 col-12 col-form-label text-md-right">Lease Amount</label>

                                        <div className="col-sm-7 col-12 m-0 p-0">
                                            <input id="Lease" type="text" className="form-control" name="Lease" value={formdata.Lease} onChange={handleInputChange} placeholder="0.00" required autoComplete="Lease" autoFocus/>
                                            {formdata.error_list && formdata.error_list.Lease && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.Lease}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>


                                    <div className="form-group row m-0 p-1 pb-2">
                                        <label htmlFor="Garbage" className="col-sm-5 col-12 col-form-label text-md-right">Garbage Deposit</label>

                                        <div className="col-sm-7 col-12 m-0 p-0">
                                            <input id="Garbage" type="text" className="form-control" name="Garbage" value={formdata.Garbage} onChange={handleInputChange} placeholder="0.00" required autoComplete="Garbage" autoFocus/>
                                            {formdata.error_list && formdata.error_list.Garbage && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.Garbage}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>


                                    <div className="form-group row m-0 p-1 pb-2">
                                        <label htmlFor="DueDay" className="col-sm-5 col-12 col-form-label text-md-right">Due Date</label>

                                        <div className="col-sm-7 col-12 m-0 p-0">
                                            <input id="DueDay" type="number" min="1" max="20" className="form-control" name="DueDay" value={formdata.DueDay} onChange={handleInputChange} placeholder="1" required autoComplete="DueDay" autoFocus/>
                                            {formdata.error_list && formdata.error_list.DueDay && 
                                                <span className="help-block text-danger">
                                                    <strong>{formdata.error_list.DueDay}</strong>
                                                </span>
                                            }
                                        </div>
                                    </div>

                                    

                                </div>
                            </div>
                            <div className="col-12 m-0 p-0">
                                <div className="form-group row mb-0 justify-content-center m-1 mt-2 p-2 border-none">
                                    
                                    {loadingresok!=='' && 
                                        <div className="col-md-10 elevation-0 mb-2 p-2 text-center border-ok">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>
                                        </div>
                                    }

                                    {loading && 
                                        <div className="col-md-12 text-center text-white">
                                                <Spinner
                                                as="span"
                                                variant='info'
                                                animation="border"
                                                size="lg"
                                                role="status"
                                                aria-hidden="true"
                                                />
                                                <span className='text-info' style={{"padding": "10px","display":"inline-block"}}>
                                                {(currentproperty !=='')?'Updating':'Adding'} ...</span>
                                                
                                        </div>
                                    }

                                    {!loading && loadingresok ==='' && 
                                        <div className="col-md-10">
                                            <button type="submit" className="btn btn-success">
                                                {(currentproperty !=='')?'Update ':'Save Property'} {currentproperty.Plotname} Information
                                            </button>
                                        </div>
                                    }

                                    {loadingres!=='' && 
                                        <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-error">
                                            <span className="help-block text-danger">
                                                <strong>{loadingres!=='' && loadingres}</strong>
                                            </span>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>

    </>
  );
}

export default AddHouse;