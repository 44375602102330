import {Link, Navigate, useNavigate} from 'react-router-dom';

function WaterbillPreviewMatchTable({waterbill,no,preview,uploadwaterbill,allchecked,handleChange}) {
    
  return (
    <tr 
        className={`unwaterbillvaluesdiv m-0 p-0 ${(uploadwaterbill.upwaterbill.filter( (f) => f === waterbill.id+'?'+waterbill.housename+'?'+waterbill.tid+'?'+waterbill.tenantname+'?'+waterbill.previous+'?'+waterbill.current+'?'+waterbill.cost+'?'+waterbill.units+'?'+waterbill.total+'?'+waterbill.waterid+'?')).length ?'bg-secondary text-info':'bg-light text-dark'}`}
        key={no} style={{"padding":"0px","margin":"0"}}>
        {waterbill.saved==='Yes'?
            <td className='m-0 p-0'>
                {allchecked?
                    <label className="selwaterbill m-0 p-1 border-info" style={{"fontSize":"12px"}}>
                        <input type="checkbox" 
                            className="selectedwaterbilltenants" 
                            name="waterbillvalues[]"
                            checked={allchecked}
                            value={waterbill.id+'?'+waterbill.housename+'?'+waterbill.tid+'?'+waterbill.tenantname+'?'+waterbill.previous+'?'+waterbill.current+'?'+waterbill.cost+'?'+waterbill.units+'?'+waterbill.total+'?'+waterbill.waterid+'?'}
                            onChange={handleChange} />
                            {no+1}
                    </label>
                :
                    <label className="selwaterbill m-0 p-1 border-info" style={{"fontSize":"12px"}}>
                        <input type="checkbox" 
                            className="selectedwaterbilltenants" 
                            name="waterbillvalues[]"
                            checked={(uploadwaterbill.upwaterbill.filter( (f) => f === waterbill.id+'?'+waterbill.housename+'?'+waterbill.tid+'?'+waterbill.tenantname+'?'+waterbill.previous+'?'+waterbill.current+'?'+waterbill.cost+'?'+waterbill.units+'?'+waterbill.total+'?'+waterbill.waterid+'?')).length}
                            value={waterbill.id+'?'+waterbill.housename+'?'+waterbill.tid+'?'+waterbill.tenantname+'?'+waterbill.previous+'?'+waterbill.current+'?'+waterbill.cost+'?'+waterbill.units+'?'+waterbill.total+'?'+waterbill.waterid+'?'}
                            onChange={handleChange} />
                            {no+1}
                    </label>
                }
            </td>
        :
            <td className='m-0 p-1'>{no+1}</td>
        }
        
        <th className='m-0 p-1'>{waterbill.housename}</th>
        <td className='m-0 p-1' title={waterbill.tenantname}>{waterbill.tenantfname}</td>
        <td className='m-0 p-1'>{waterbill.previous}</td>
        <td className='m-0 p-1'>{waterbill.current}</td>
        <td className='m-0 p-1'>{new Number(waterbill.cost).toFixed(2)}</td>
        <td className='m-0 p-1'>{waterbill.units}</td>
        <td className='m-0 p-1'>{new Number((waterbill.total)+(waterbill.total_os)).toFixed(2)}</td>
        {preview &&
            <>
                <td className='m-0 p-1'>{waterbill.saved_bill}</td>
                <td className='m-0 p-1'>{waterbill.saved}</td>
                <td className='m-0 p-1'>{waterbill.prevmatches}</td>
            </>
        }
        
    </tr>
  );
}

export default WaterbillPreviewMatchTable;