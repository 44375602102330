import {Link, Navigate, useNavigate} from 'react-router-dom';
import TenantsHouseLinks from './TenantsHouseLinks';

function TenantsTable({property,no,handleShowAddTenant,handleShowAddProperty,deleteTenant}) {
  return (
        <tr key={no} style={{"padding":"0px","margin":"0","backgroundColor":"#FFFFFF"}}>
            <th className='m-0 p-1'>{no+1}</th>
            <th className='m-0 p-1'><Link to={'/properties/mgr/tenants/'+property.id}>{property.Fname}</Link></th>
            <td className='m-0 p-0'>{property.Oname}</td>
            <td className='m-0 p-0'>{property.Gender}</td>
            <td className='m-0 p-0'>{property.IDno}</td>
            <td className='m-0 p-0'>{property.PhoneMasked}</td>
            <td className='m-0 p-1'>{property.Status}</td>
            <td className='m-0 p-1'>{property.Houses}</td>
            <td className='m-0 p-0' style={{"whiteSpace":"nowrap"}}>
                {property.housesdata  && property.housesdata.map((houses,key) => (
                    <TenantsHouseLinks houses={houses} key={key} no={key} handleShowAddProperty={handleShowAddProperty}/>
                ))
                }
            </td>
            <td className=' m-0 p-1'>
            <div className='d-flex justify-content-center my-auto'>
                {property.Houses===0 &&
                    <button className='bg-success m-0 ml-1 p-0 pl-2 pr-2 border-info text-white' onClick={()=>{handleShowAddProperty(property)}}><small><i className='fa fa-plus'></i> Assign New House</small></button>
                }
            
            <button className='bg-warning m-0 ml-1 p-0 pl-2 pr-2 border-info text-dark' onClick={()=>{handleShowAddTenant(property)}}><small><i className='fa fa-edit'></i> Edit</small></button>
            <button className='bg-danger m-0 ml-1 p-0 pl-2 pr-2 border-info text-white' onClick={()=>{deleteTenant(property)}}><small><i className='fa fa-trash'> Delete</i></small></button>
            </div>
            </td>

        </tr>
  );
}

export default TenantsTable;