import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function HomeFooter() {
  return (
    <>
        <footer className="main-footer text-center bg-info text-white" style={{"bottom":"1%","width":"100%"}}>
            <strong> &copy;  Wagitonga Agency Limited.</strong> 
          </footer>  
    </>
  );
}

export default HomeFooter;