import Carousel from 'react-bootstrap/Carousel';

import { Link } from 'react-router-dom';

function LoadWaterbill({waterbill,handleShow}) {
    

  return (
    <Carousel keyboard={true} indicators={true}>
        {waterbill && waterbill.map((waters,key) => (
            <Carousel.Item key={key}>
                <div key={key} className="row m-0 mb-0 ml-4 mr-4 pl-2 pr-2">
                    <div className="col-12 m-0 mb-2 p-1">
                        <div className="card-box mb-1">
                            <div className='card-icon'>
                                <span className="card-box-icon bg-info elevation-1">{waters.plotcode}</span>
                                <span className="card-box-icon bg-light elevation-1"><small>Sent:{waters.totalbillsmsghse}</small></span>
                                <span className="card-box-icon bg-light elevation-1"><small>Units:{waters.totalunits}</small></span>
                            </div>
                            <div className="card-box-content">
                                <span className="card-box-text"><span className='title-sm bold'>Billed/Occupied: </span> {waters.totalbillshse}/{waters.totalhouseshse} ({waters.curmonth}) </span>
                                <span className="card-box-text"><span className='title-sm bold'>Billed Waterbill: </span> Kshs. {new Number(waters.totals).toFixed(2)} </span>
                                <span className='card-box-text'>
                                    <div className="card-box-links m-0 p-1">
                                        <div className="row m-0 p-0">
                                            <div className='col-4 p-0 m-0'>
                                                <Link to={'/properties/update/waterbill/'+waters.id+'/'+waters.month} className="p-0 m-0 pl-1 pr-1 btn btn-outline-primary"> <i className='fa fa-upload text-sm'></i></Link>
                                            </div>
                                            
                                            <div className='col-4 p-0 m-0'>
                                            {/* /properties/update/waterbill/:id */}
                                                {/* <Link to={'/properties/update/waterbill/'+waters.id+'/'+waters.month} className="p-0 m-0 pl-1 pr-1 btn btn-outline-success"> <i className='fa fa-envelope text-sm'></i></Link> */}
                                                <Link to={'/properties/update/waterbill/'+waters.id+'/'+waters.month} className="p-0 m-0 pl-1 pr-1 btn btn-outline-success"> <i className='fa fa-envelope text-sm'></i></Link>
                                            </div>

                                            <div className='col-4 p-0 m-0'>
                                                <Link to="#" className="p-0 m-0 pl-1 pr-1 btn btn-outline-secondary" onClick={()=>{handleShow(waters)}}> <i className='fa fa-download text-sm'></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Carousel.Item>
        ))
        }
    </Carousel>
  );
}

export default LoadWaterbill;