import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';

function TableSmallSpinner() {
    
  return (
    <>
      <Card.Body>
        <Placeholder as={Card.Text} animation="glow">
            <Placeholder  className='border-info bg-white' variant="blue" style={{"padding": "2%","marginTop":"-20px"}}>
                <Placeholder className='border-info bg-info' style={{"height":"25px","borderRadius":"30px"}} md={1} /> <Placeholder className='border-info bg-info' style={{"height":"25px","borderRadius":"30px"}} md={1} /> <Placeholder className='border-info bg-info'  style={{"height":"25px","borderRadius":"30px"}} md={8} /> <Placeholder className='border-info bg-info'  style={{"height":"25px","borderRadius":"30px"}} md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={1} /> <Placeholder className='border-info bg-info' md={8} /> <Placeholder className='border-info bg-info' md={1} /> 
                
                
            </Placeholder> 
        
        </Placeholder>
      </Card.Body>
    </>
  );
}

export default TableSmallSpinner;