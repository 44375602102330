import {Link, Navigate, useNavigate} from 'react-router-dom';

function PropertyTable({property,no,handleShowAddHouse,deleteHouse}) {
    
  return (
    <tr key={no} style={{"padding":"0px","margin":"0","backgroundColor":"#FFFFFF"}}>
        <th className='m-0 p-1'>{no+1}</th>
        <th className='m-0 p-1'><Link to={'/properties/house/'+property.Plot+'/'+property.id}>{property.Housename}</Link></th>
        <td className='m-0 p-0'>{property.tenantname}</td>
        <td className='m-0 p-1'>{property.Rent}</td>
        <td className='m-0 p-1'>{property.Deposit}</td>
        <td className='m-0 p-1'>{property.Water}</td>
        <td className='m-0 p-1'>{property.Kplc}</td>
        <td className='m-0 p-1'>{property.Lease}</td>
        <td className='m-0 p-1'>{property.Garbage}</td>
        <td className='m-0 p-1'>{property.DueDay}</td>
        <td className='m-0 p-1'>{property.Status}</td>
        <td className='d-flex justify-content-center m-0 p-1'>
            <button className='bg-warning m-0 ml-1 p-0 pl-2 pr-2 border-info text-dark' onClick={()=>{handleShowAddHouse(property)}}><small><i className='fa fa-edit'></i></small></button>
            <button className='bg-danger m-0 ml-1 p-0 pl-2 pr-2 border-info text-white' onClick={()=>{deleteHouse(property)}}><small><i className='fa fa-trash'></i></small></button>
        </td>
        
    </tr>
  );
}

export default PropertyTable;