import DashSideNavBar from './DashSideNavBar';
import DashNavBar from './DashNavBar';
import { useEffect, useState } from 'react';
import DashFooter from './DashFooter';
import Spinner from 'react-bootstrap/Spinner';

import axios from 'axios';

import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useParams } from 'react-router';

import Swal from 'sweetalert';
import AddHouse from './AddHouse';
import TableSmallSpinner from '../spinners/TableSmallSpinner';
import PropertyTable from './Tables/PropertyTable';



function ManageProperty(props) {
    document.title="Manage Property";
    
    const navigate=useNavigate();


    // State for date selected by user
    const [selectedDate, setSelectedDate] = useState(new Date());
    let par=useParams()

    const [id,setID]=useState((par.id)?par.id:'')

    console.log(id)

    const [closed,setClosed]=useState(false)
    const [isOpen, setIsOpen] = useState(false)



    const [date, setDate] = useState(new Date());

    const [waterbillpropertyid,setWaterbillPropertyId]=useState([""]);
    const [waterbillmonth,setWaterbillMonth]=useState([""]);
    const [updatemonths, setUpdateMonths] = useState([""]);
    const [propertyinfo, setPropertyinfo] = useState([""]);
    
    const [propertydata, setPropertydata] = useState([]);
    const [housedata, setHousedata] = useState([]);
    const [waterbilldata, setWaterbillData] = useState([""]);
    
    const [currentwaterbill, setCurrentWaterbill] = useState([""]);

    const [currentproperty, setCurrentProperty] = useState([""]);
    const [currenthouse, setCurrentHouse] = useState('');
    const [showaddhouse,setShowAddHouse]=useState(false);
    
    const [preview, setPreview] = useState(false);

    const [search,setSearch]=useState({
        value:'',
        result:[]
    })

    const [searchhouse,setSearchhouse]=useState({
        value:'',
        result:[]
    })

    const [uploadwaterbill,setUploadWaterbill]=useState({
        upwaterbill:[]
      })
    
    const [totalvalid,setTotalValid]=useState(0)
    const [allchecked,setAllchecked]=useState(false);
    const [totalchecked,setTotalChecked]=useState(0);

    const [show,setShow]=useState(false);
    const [showdownloadpayments,setShowDownloadPayments]=useState(false);
    const [property,setProperty]=useState('');
    const [propertyid,setPropertyId]=useState('');

    const [showmonth,setShowMonth]=useState(false);

    const [redirect,setRedirect]=useState(false);
    const [waterbillurl,setWaterbillUrl]=useState('');

    // const [loading,setLoading]=useState(true);
    const [loadingmonths,setLoadingMonths]=useState(true);
    const [loadingwater,setLoadingWater]=useState(true);
    const [loadingstats,setLoadingStats]=useState(true);
    

    const [loading,setLoading]=useState(false);

    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');
    
    const [selectedmonth,setSelectedMonth]=useState('');
    const [totalmonths,setTotalMonths]=useState(0);

    
    

    // /properties/dash/payments
    // /properties/dash/water
    // /properties/dash/water/prev
    // /properties/update/waterbill/1/2022 12
    // /properties/updateload/waterbill/1/2022 10
    // /properties/download/Reports/Waterbill/1/2022%2012
    // /properties/save/waterbill/uploadupdate


    useEffect(()=>{
        let doneloading=true;
        
        const getPrevMonths = async (e) => { 
            const arr = [];
                arr.push({value: '', label: 'Select Month' });
            const arr1 = [];
                arr1.push({value: '', label: 'Select Property' });
            const arr2 = [];    
            let url=`/api/properties/manage/load/${id}`;
            if(id===''){
                url='/api/properties/manage/load';
            }
            else{
                if(id==='all'){
                    url=`/api/properties/manage/load`;
                }
                else{
                    return false;
                }
                
            }
            await axios.get(url)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        let respropertyinfo = response.data.propertyinfo;

                        setCurrentProperty(response.data.thisproperty)
                        let resthisproperty = response.data.thisproperty;

                        setPropertydata(response.data.propertyinfo)
                        setHousedata([])

                        respropertyinfo.map((monthsup) => {
                            return arr1.push({value: monthsup.id, label: monthsup.Plotname , data: monthsup});
                        });
                        setPropertyinfo(arr1)
                        
                        let options=[];
                        if(id!==''){
                            options={value: resthisproperty.id, label: resthisproperty.Plotname+'('+resthisproperty.Plotcode+')' , data: resthisproperty}
                        }
                        
                        setWaterbillPropertyId(options)
                        setLoadingMonths(false)
                    }
                    else if(response.data.status=== 401){
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    
                }
            })
            .catch(error=>{
                Swal("Error",""+error,"error");
            })
        };
        getPrevMonths();

        return ()=>{
            doneloading=false;
            
        }
    },[id])


    useEffect(()=>{
        let doneloading=true;
        if (doneloading) {
            setLoadingMonths(true)
        }
        const getProperties = async (e) => { 
            const arr1 = [];
                arr1.push({value: '', label: 'Select Property' });
            let url=`/api/properties/manage/load/${id}`;
            

            if(id===''){
                setLoadingMonths(false)
                return false;
            }
            else{
                if(id==='all'){
                    url=`/api/properties/manage/load`;
                }
                else{
                    url=`/api/properties/manage/load/${id}`;
                }
            }


            await axios.get(url)
            .then(response=>{
                if (doneloading) {
                    if(response.data.status=== 200){
                        let respropertyinfo = response.data.propertyinfo;

                        setCurrentProperty(response.data.thisproperty)
                        let resthisproperty = response.data.thisproperty;

                        respropertyinfo.map((monthsup) => {
                            return arr1.push({value: monthsup.id, label: monthsup.Plotname , data: monthsup});
                        });
                        setPropertyinfo(arr1)

                        setHousedata(response.data.houseinfo)
                        setPropertydata([])
                        
                        let options=[];
                        if(id!==''){
                            options={value: resthisproperty.id, label: resthisproperty.Plotname+'('+resthisproperty.Plotcode+')' , data: resthisproperty}
                        }
                        
                        setWaterbillPropertyId(options)
                       
                        setLoadingMonths(false)
                    }
                    else if(response.data.status=== 401){
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    else{
                        setLoadingMonths(false)
                    }
                    setLoadingMonths(false)
                }
            })
            .catch(error=>{
                Swal("Error",""+error,"error");
                setLoadingMonths(false)
            })
        };
        getProperties();

        return ()=>{
            doneloading=false;
        }
    },[id])

    // useEffect(()=>{
    //     let doneloading=true;
        
    //     const getProperties = async (e) => { 
    //         const arr1 = [];
    //             arr1.push({value: '', label: 'Select Property' });
    //         let url=`/api/properties/manage/load/${id}`;
            

    //         if(id===''){
    //             setLoadingMonths(false)
    //             return false;
    //         }
    //         else{
    //             if(id==='all'){
    //                 url=`/api/properties/manage/load`;
    //             }
    //             else{
    //                 url=`/api/properties/manage/load/${id}`;
    //             }
    //         }


    //         await axios.get(url)
    //         .then(response=>{
    //             if (doneloading) {
    //                 if(response.data.status=== 200){
    //                     let respropertyinfo = response.data.propertyinfo;

    //                     setCurrentProperty(response.data.thisproperty)
    //                     let resthisproperty = response.data.thisproperty;

    //                     respropertyinfo.map((monthsup) => {
    //                         return arr1.push({value: monthsup.id, label: monthsup.Plotname , data: monthsup});
    //                     });
    //                     setPropertyinfo(arr1)

    //                     setHousedata(response.data.houseinfo)
    //                     setPropertydata([])
                        
    //                     let options=[];
    //                     if(id!==''){
    //                         options={value: resthisproperty.id, label: resthisproperty.Plotname+'('+resthisproperty.Plotcode+')' , data: resthisproperty}
    //                     }
                        
    //                     setWaterbillPropertyId(options)
                       
    //                 }
    //                 else if(response.data.status=== 401){
    //                     Swal("Error",response.data.message,"error");
    //                 }
    //                 else if(response.data.status=== 500){
    //                     Swal("Error",response.data.message,"error");
    //                 }
                    
    //             }
    //         })
    //         .catch(error=>{
    //             Swal("Error",""+error,"error");
    //         })
    //     };
    //     getProperties();

    //     return ()=>{
    //         doneloading=false;
            
    //         setLoadingMonths(false)
    //     }
    // },[id])


    useEffect(()=>{
        if(id==='all'){
            let thisurl=`/properties/manage`;
            navigate(thisurl)
        }
        else{
            if(id!==''){
                let thisurl=`/properties/manage/${id}`;
                navigate(thisurl)
            }
        }
       
    },[id])

    
    
    const handleShowAddProperty = () => {
        setShowAddHouse(true);
        setCurrentHouse('')
    };


    const handleShowAddHouse = (property) => {
        setShowAddHouse(true);
        setCurrentHouse(property)
    };

    const handleCloseAddHouse = () => {
        setShowAddHouse(false);
        document.title="Manage Property";
    };
  
    function handleWaterbillMonthChange(val) {
        setLoadingMonths(true)
        let monthoptions={value: val.value, label: val.label}
        setWaterbillMonth(monthoptions) 
        setLoadingMonths(false)
    }

    function handlePropertyChange(val) {
        setLoadingMonths(true)
        setID(val.value)
        let options={value: val.value, label: val.label , data: val}
        setWaterbillPropertyId(options) 
        setLoadingMonths(false)
    }

    const handleSearchChange =(e) =>{
        setLoadingMonths(true)
        // if(propertydata.length>0){
        //     const results=propertydata.filter(property =>{
        //         if(e.target.value=== '') return propertydata
        //         return property.Plotname.toLowerCase().includes(e.target.value.toLowerCase()) || property.Plotcode.toLowerCase().includes(e.target.value.toLowerCase())
        //     })
        //     setSearch({
        //         value:e.target.value,
        //         result:results
        //     })
        // }
        // else if(housedata.length>0){
            const results=housedata.filter(house =>{
                if(e.target.value=== '') return housedata
                return house.Housename.toLowerCase().includes(e.target.value.toLowerCase()) || house.tenantname.toLowerCase().includes(e.target.value.toLowerCase()) || (house.Rent.toString().toLowerCase()===e.target.value.toLowerCase()) || (house.Water.toString().toLowerCase()===e.target.value.toLowerCase()) || house.Status.toLowerCase().includes(e.target.value.toLowerCase())
            })
            setSearchhouse({
                value:e.target.value,
                result:results
            })
        // }
        setLoadingMonths(false)
    }


    const handleChange= (e) => {
        const {value,checked}=e.target;
        const {upwaterbill} =uploadwaterbill;

        if(value==='all'){
            const arr = [];
            if(checked){
                waterbilldata.map((waterbilld) => {
                    if(waterbilld.waterid===null){
                        if(waterbilld.tid!=='Vacated'){
                            if(waterbilld.prevmatches==='Yes'){
                                arr.push(waterbilld.id+'?'+waterbilld.housename+'?'+waterbilld.tid+'?'+waterbilld.tenantname+'?'+waterbilld.previous+'?'+waterbilld.current+'?'+waterbilld.cost+'?'+waterbilld.units+'?'+waterbilld.total+'?'+waterbilld.waterid+'?')
                            }
                        }
                    }
                    else{
                        arr.push(waterbilld.id+'?'+waterbilld.housename+'?'+waterbilld.tid+'?'+waterbilld.tenantname+'?'+waterbilld.previous+'?'+waterbilld.current+'?'+waterbilld.cost+'?'+waterbilld.units+'?'+waterbilld.total+'?'+waterbilld.waterid+'?')
                    }
                })
                setUploadWaterbill({
                    upwaterbill:arr,
                })
            }
            else{
                setUploadWaterbill({
                    upwaterbill:[],
                })
            }
            
        }
        else{
            if(checked){
                setUploadWaterbill({
                    upwaterbill:[... upwaterbill,value],
                });
                
            }
            else{
                setUploadWaterbill({
                    upwaterbill:upwaterbill.filter((e) => e !== value),
                })
            }

        }
    }

    // savepid
    // savemonth
    // waterbillvaluesupdate


    const deleteHouse= (house)=>{
        const form={
            id:house.id,
        }

        let title='Delete '+house.Housename;
        let text="This will remove this House from the Property.";
        Swal({
            title:title+' ?',
            text:text,
            buttons:true,
            infoMode:true,
        })
        .then((willcontinue) =>{
            if(willcontinue){
                setLoading(true);
                axios.post('/api/delete/house/save',form)
                .then(response=>{
                    if(response.data.status=== 200){
                        Swal("Success",response.data.message);
                    }
                    else if(response.data.status=== 401){
                        Swal("Error",response.data.message,"error");
                    }
                    else if(response.data.status=== 500){
                        Swal("Error",response.data.message,"error");
                    }
                    setLoading(false);
                })
                .catch((error)=>{
                    Swal("Error",""+error,"error");
                    setLoading(false);
                })
            }
            else{
                setLoading(false);
            }
        })

    }

  return (
    <>
    <div className="wrapper">
        <DashNavBar setClosed={setClosed} closed={closed} active='manage'/>
        <DashSideNavBar setClosed={setClosed} closed={closed} active='manage'/>
        {/* className={`nav-link ${active==='home'?'active':''}`} */}
        
        <main className="py-3">
            <div className={`content-wrapper ${closed?'closed':''}`}>

                <section className="content">
                    <div className="container">
                        <div className="row justify-content-center">


                        <div className="col-12">
                            <div className="row m-0 p-0">

                                <div className="col-md-12 m-0 p-0 mt-2 mb-4">
                                    <div className="card border-info m-0 p-0" >
                                        <div className="card-header bg-info text-white elevation-2 m-0 p-0">
            
                                            <div className='row justify-content-center text-center p-1 m-0'>
                                                <div className="col-12 col-lg-6 m-0 p-1 text-sm text-dark">
                                                    {loadingmonths &&
                                                        <Spinner  variant="light" size="md" role="status"></Spinner>
                                                    }
                                                    {!loadingmonths &&
                                                        <Select
                                                            className='text-sm'
                                                            placeholder= "Select Property"
                                                            value={waterbillpropertyid}
                                                            name="waterbill-property"
                                                            options={propertyinfo}
                                                            onChange={handlePropertyChange}
                                                        />
                                                    }
                                                </div>

                                                <div className="col-12 col-lg-6 text-xs float-right m-0 p-0">
                                                    {waterbillpropertyid.label!==undefined &&
                                                        <button className='btn btn-primary border-info m-1 p-1 pl-2 pr-2'><Link to='/properties/manage' className='text-white'><i className='fa fa-chevron'></i> Properties</Link></button>
                                                    } 
                                                    <button className='btn btn-success border-info m-1 p-1 pl-2 pr-2' onClick={()=>{handleShowAddProperty()}}><small><i className='fa fa-plus-circle'></i> House ({housedata.length})</small></button>
                                                    
                                                    {housedata  && housedata.length>0 && <input onChange={handleSearchChange} value={searchhouse.value} className='border-info p-1 pt-0 pb-0' placeholder='Search House' />}
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className="card-body text-center m-0 p-1">
                                        
                                            <div className="row m-0 p-0">
                                                {loadingmonths &&
                                                    <div className="col-12 text-left m-0 p-1 mt-1 mb-2">
                                                        <TableSmallSpinner />
                                                    </div>
                                                }
                                                {!loadingmonths &&
                                                    <>
                                                        <div className="tableinfo col-12 m-0 p-0" style={{"overflowX":"auto"}}>
                                                        <table border="1" className="table table-hover table-bordered text-xs" id="example1">
                                                            
                                                            {housedata  && housedata.length>0 &&
                                                                <thead  >
                                                                    
                                                                <tr  style={{"color":"white","backgroundColor":"#77B5ED"}}>
                                                                    <th className='elevation-2 m-0 p-1'>Sno</th>
                                                                    <th className='elevation-2 m-0 p-1'>Housename</th>
                                                                    <th className='elevation-2 m-0 p-1'>Tenant</th>
                                                                    <th className='elevation-2 m-0 p-1'>Rent</th>
                                                                    <th className='elevation-2 m-0 p-1'>Deposit</th>
                                                                    <th className='elevation-2 m-0 p-1'>KPLC</th>
                                                                    <th className='elevation-2 m-0 p-1'>Water</th>
                                                                    <th className='elevation-2 m-0 p-1'>Lease</th>
                                                                    <th className='elevation-2 m-0 p-1'>Bin</th>
                                                                    <th className='elevation-2 m-0 p-1'>Due</th>
                                                                    <th className='elevation-2 m-0 p-1'>Status</th>
                                                                    <th className='elevation-2 m-0 p-1'>Action</th>
                                                                </tr></thead>
                                                            }
                                                            <tbody>
                                                                
                                                                {housedata  && housedata.length>0 &&
                                                                    <>
                                                                        {(searchhouse.value==='')?
                                                                            <>
                                                                                {housedata  && housedata.map((property,key) => (
                                                                                    <PropertyTable property={property} key={key} no={key} handleShowAddHouse={handleShowAddHouse} deleteHouse={deleteHouse} />
                                                                                ))
                                                                                }
                                                                            </>
                                                                        :
                                                                            <>
                                                                                {searchhouse.result  && searchhouse.result.map((property,key) => (
                                                                                    <PropertyTable property={property} key={key} no={key} handleShowAddHouse={handleShowAddHouse} deleteHouse={deleteHouse} />    
                                                                                ))
                                                                                }
                                                                            </>
                                                                        
                                                                        }
                                                                    </>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                                }
                                            </div>

                                        </div>

                                        

                                    </div>
                                </div>

                                {showaddhouse && 
                                    <AddHouse showaddhouse={showaddhouse} handleCloseAddHouse={handleCloseAddHouse} currentproperty={currentproperty} currenthouse={currenthouse}/>
                                }

                                
                            </div>
                        </div>

                        
                            
                        </div>

                    </div>


                </section>
            </div>
        </main>


        <DashFooter />
      </div>
    </>
  );
}

export default ManageProperty;