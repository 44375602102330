import Button from 'react-bootstrap/Button';
import HomeFooter from './HomeFooter';
import HomeNavBar from './HomeNavBar';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {Link, Navigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import logo from '../../assets/img/wagitonga1.png'

function Login() {

    document.title="Login";
    const [redirect,setRedirect]=useState(false);
    const [loading,setLoading]=useState(false);
    const [url,setUrl]=useState('');
    
    const [loadingresok,setLoadingResOk]=useState('');
    const [loadingres,setLoadingRes]=useState('');


    const [formdata,setFormData]=useState({
        email:'',
        password:'',
        error_list:[],
    });
    
    const handleInputChange=(e)=>{
        e.persist();
        setFormData({...formdata,[e.target.name]:e.target.value})
    }

    const [authname,setAuthName]=useState('');
    
    useEffect(()=>{
        if(localStorage.getItem("auth_token")){
            setAuthName('')
            setRedirect(true);
            setUrl('/dashboard');
        }
        else{
            setAuthName(localStorage.getItem("auth_name"))
        }
      },[authname])
      
    if(!redirect){
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_name');
    }

    

    const authUser= (e)=>{
        e.preventDefault();
        setLoading(true);
        setLoadingRes("")
        setLoadingResOk("")
        const form={
            email:formdata.email,
            password:formdata.password,
        }

        axios.get('/sanctum/csrf-cookie').then(res =>{
            axios.post('/api/login',form)
            .then(response=>{
                if(response.data.status=== 200){
                    setLoadingResOk("Logged In. Redirecting...")
                    setLoadingRes("")
                    setFormData({...formdata,error_list:[]});
                    localStorage.setItem('auth_token',response.data.token);
                    localStorage.setItem('auth_name',response.data.username);
                    setRedirect(true);
                    setUrl('/dashboard');

                }
                else if(response.data.status=== 401){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else if(response.data.status=== 500){
                    setLoadingRes(response.data.message)
                    setLoadingResOk("")
                    setFormData({...formdata,error_list:[]});
                }
                else{
                    setFormData({...formdata,error_list:response.data.errors});
                }
                setLoading(false);

            })
            .catch((error)=>{
                setLoadingRes(""+error)
                setLoadingResOk("")
                setLoading(false);
            })
        })
        .catch((error)=>{
            setLoadingRes(""+error)
            setLoadingResOk("")
            setLoading(false);
        })

    }

    if(redirect){
        return <Navigate to={url} />
    }
  return (
    <>
    <HomeNavBar active='login'/>
    <main className='elevation-0 mt-3' style={{"paddingTop":"50px","minHeight": "calc(100vh - 3rem)"}}>
        <div className="">
            <div className="d-flex justify-content-center">
                <div className="mt-4 p-3">
                    <div className="card border-info m-2 p-2 elevation-2">
                        {/* <div className="m-1 bg-white border-none">
                        
                            <h3 style={{"textAlign": "center"}}>
                                <Link className="navbar-brand" to="/">
                                    <img src={logo} alt="Wagitonga Logo" className="mx-auto brand-image elevation-0 m-0 " style={{"width":"60%","borderRadius": "10px 10px 1px 1px"}}/>
                                </Link>
                            </h3>
                            
                        </div> */}

                        <div className="card-body row border-none m-2 p-2" style={{"paddingTop": "10px"}}>
                        <h4 className='text-info' style={{"textAlign": "center"}}> Login</h4>
                            <form onSubmit={authUser}>
                                {!loading && 
                                    <>
                                        <div className="form-group row p-1 m-0">
                                            <label htmlFor="Username" className="col-12 col-md-3 col-lg-3 m-0 p-1 col-form-label text-center text-md-left text-lg-right">User</label>

                                            <div className="col-12 col-md-9 col-lg-9 m-0 p-1">
                                                <input id="Username" type="text" className="form-control" name="email" placeholder="Username or Email Address" value={formdata.email} onChange={handleInputChange} required autoComplete="Username" autoFocus/>
                                                {formdata.error_list && formdata.error_list.email && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.email}</strong>
                                                    </span>
                                                }
                                                
                                            </div>
                                        </div>

                                        <div className="form-group row p-1 m-0">
                                            <label htmlFor="password" className="col-12 col-md-3 col-lg-3 m-0 p-1 col-form-label text-center text-md-left text-lg-right">Password</label>

                                            <div className="col-12 col-md-9 col-lg-9 m-0 p-1">
                                                <input id="password" type="password" className="form-control" name="password" placeholder="Password" value={formdata.password} onChange={handleInputChange} required autoComplete="current-password"/>
                                                {formdata.error_list && formdata.error_list.password && 
                                                    <span className="help-block text-danger">
                                                        <strong>{formdata.error_list.password}</strong>
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className="form-group row mb-0 justify-content-center">
                                    
                                    {loadingresok!=='' && 
                                        <div className="col-md-10 elevation-0 mb-2 p-2 text-center border-ok">
                                            <span className="help-block text-success">
                                                <strong>{loadingresok!=='' && loadingresok}</strong>
                                            </span>
                                        </div>
                                    }

                                    {loading && 
                                        <div className="col-md-12 text-center text-white">
                                                
                                                <span className='text-black' style={{"padding": "10px","display":"inline-block"}}>Please Wait ...</span>
                                                <br/>
                                                <Spinner
                                                    as="span"
                                                    variant='info'
                                                    animation="border"
                                                    size="lg"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span className='text-info' style={{"padding": "10px","display":"inline-block"}}>Signing In...</span>
                                                
                                                
                                             
                                        </div>
                                    }

                                    {!loading && loadingresok ==='' && 
                                        <div className="row m-0 p-0">
                                            <div className="col-7 m-0 p-0">
                                                <Link className="btn btn-link " to="/password/request">
                                                    Forgot Your Password?
                                                </Link>
                                            </div>
                                            <div className="col-5 m-0 p-0">
                                                <button type="submit" className="btn btn-primary border-info">
                                                    Login
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    {loadingres!=='' && 
                                        <div className="col-md-10 elevation-0 mt-2 p-2 text-center border-error">
                                            <span className="help-block text-danger">
                                                <strong>{loadingres!=='' && loadingres}</strong>
                                            </span>
                                        </div>
                                    }
                                    
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </main>
    
      
    </>
  );
}

export default Login;